import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { MyAlert } from './MyAlert';

@Injectable({
  providedIn: 'root'
})

export class RestService {
  //apiRoot: string = 'http://localhost:8000/api/';
  apiRoot: String = '/api/'

  constructor(private http: HttpClient) { }
  private currentWeek = new Subject<any>();
  private notifications = new Subject<MyAlert[]>();

  getNotifications(): Observable<MyAlert[]> {
    return this.notifications.asObservable();
  }

  getCurrentWeek(): Observable<any> {
    return this.currentWeek.asObservable();
  }

  setProcSlot(cmd) {

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: httpHeaders
    };
    this.http.post(this.apiRoot + 'update/', cmd,options).subscribe((res: any[]) => {

      this.currentWeek.next(res);
    });
  }

  getRefreshWeek(tweek) {
    this.http.get(this.apiRoot + 'refresh/' + tweek + '/').subscribe((res: any[]) => {

      this.currentWeek.next(res);
    });
  }

  getFreshNotifications() {
    this.http.get(this.apiRoot + 'notifications/').subscribe((res: MyAlert[]) => {
      this.notifications.next(res);
    });
  }




}
