import { Component } from '@angular/core';
import {NgbProgressbarConfig, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { RestService } from './rest.service';
import { MyAlert } from './MyAlert';
import { Observable, timer, Subscription } from 'rxjs';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NgbProgressbarConfig]
})
export class AppComponent {
  public caldata = [];
  public currentWeek = 0;

  polling$: Observable<number> = timer(0, 3000);
  andminURL = './admin/'
  //andminURL = 'http://localhost:8000/admin/'



  constructor(config: NgbProgressbarConfig,
    private modalService: NgbModal,
    private rest: RestService) {

    config.striped = true;
    config.animated = true;
  }

  private subNotif: Subscription;
  private subCal: Subscription;
  private subPolling: Subscription;
  selectedCal = 1;
  alerts: MyAlert[];

  ngOnDestroy() {
    this.subNotif.unsubscribe();
    this.subCal.unsubscribe();
    this.subPolling.unsubscribe();
  }
  tabchange(event){
    console.log(event)
    //this.selectedCal = event.activeId
  }
  ngOnInit() {


    this.subCal = this.rest.getCurrentWeek().subscribe((res : any[])=>{
        this.caldata = res;
        this.currentWeek = res[0].dataset.semaine;
    });

    this.subNotif = this.rest.getNotifications().subscribe((res : MyAlert[])=>{
      this.alerts = res;
    });

    this.subPolling = this.polling$.subscribe(second =>
      {
        this.rest.getFreshNotifications();
      }
      );
    this.rest.getRefreshWeek(0);

  }

  title = 'tplprgm';
  closeResult: string;


  previous( week){
    if (week > 0){
      this.pagination(week-1);
    }
  }
  next(week){
    if (week < 3){
      this.pagination(week+1);
    }
  }


  private pagination(tweek){
    this.rest.getRefreshWeek(tweek);
  }
  trackByFn(index: any, item: any) {
    return index;
 }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  setProcSlot(procs, calId, tweek, datetime){
    let cmd = {"calId": calId,"day":{"datetime":datetime,"procs":procs},"tweek": tweek}

    this.rest.setProcSlot(cmd);
    this.modalService.dismissAll();

  }


  onSubmit(val){
    console.log(val);
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  private counProcs(procs){
    let len = procs.length
    let pset = 0
    for (let p of procs){
      if (p != ""){
        pset = pset +1;
      }
    }
    return (pset / len) * 100
  }

  progressProcs(procs){
    return this.counProcs(procs)
  }

  progressProcsType(procs){
    let len = this.counProcs(procs);
    if (len == 100){
      return "danger"
    }else if (len > 50 && len < 80){
      return "warning"
    }else{
      return "success"
    }
  }



}


